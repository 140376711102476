import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Link } from '@mui/material';
import { articlesData } from '../data/articles';

const ArticleDetail = () => {
  const { category, slug } = useParams();
  const articles = articlesData[category] || [];
  const article = articles.find((a) => a.slug === slug);

  if (!article) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h5" gutterBottom>
          Article Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          We couldn't locate the article you were looking for.
        </Typography>
        <Link component={RouterLink} to={`/articles/${category}`} color="secondary">
          Back to {category}
        </Link>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
        {article.title}
      </Typography>
      {/* 
        We assume the article content may contain HTML. 
        Using `dangerouslySetInnerHTML` for demonstration. In production, ensure the content is sanitized.
      */}
      <Box sx={{ mb: 4 }} dangerouslySetInnerHTML={{ __html: article.content }} />
      <Link component={RouterLink} to={`/articles/${category}`} color="secondary">
        Back to {category}
      </Link>
    </Container>
  );
};

export default ArticleDetail;
