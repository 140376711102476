// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0D47A1',  // A strong blue hue for a tech-like feel
    },
    secondary: {
      main: '#00C853',  // A vibrant green accent
    },
    background: {
      default: '#121212', // Dark background for the app
      paper: '#1E1E1E',   // Slightly lighter panels
    },
    text: {
      primary: '#ffffff',
      secondary: '#B0B0B0',
    }
  },
  typography: {
    fontFamily: 'Roboto, Inter, sans-serif',
    button: {
      textTransform: 'none'
    }
  },
});

export default theme;
