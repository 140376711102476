export const articlesData = {
    'getting-started': [
      {
        title: "How to Get Started with Orthinas Forms",
        slug: "how-to-get-started-with-orthinas-forms",
        excerpt: "Learn the basics of setting up and using Orthinas Forms to streamline your data collection.",
        content: `
          <h2>Introduction</h2>
          <p>Orthinas Forms allows you to build, share, and analyze forms with ease. In this guide, we'll walk you through the initial setup and the fundamentals to help you get started.</p>
          <h3>Step 1: Create an Account</h3>
          <p>Visit <a href="https://forms.orthinas.com">forms.orthinas.com</a>, sign up, and verify your email to begin.</p>
          <h3>Step 2: Build Your First Form</h3>
          <p>Use the intuitive drag-and-drop interface to add fields, set up logic, and configure submission options.</p>
          <h3>Next Steps</h3>
          <p>Explore advanced features, integrate with third-party tools, and review submissions in real-time.</p>
        `
      },
      {
        title: "Understanding Orthinas Dashboard",
        slug: "understanding-orthinas-dashboard",
        excerpt: "A quick overview of the Orthinas dashboard and its key elements.",
        content: "<p>The Orthinas dashboard is your control center for all your forms, analytics, and settings...</p>"
      }
    ],
    'advanced-features': [
      {
        title: "Automating Workflows with Orthinas",
        slug: "automating-workflows-with-orthinas",
        excerpt: "Discover how to leverage conditional logic and integrations to automate data-driven processes.",
        content: "<p>...</p>"
      }
    ],
    'troubleshooting': [
      {
        title: "Resolving Common Form Submission Errors",
        slug: "resolving-common-form-submission-errors",
        excerpt: "Tips on diagnosing and fixing common issues you might encounter.",
        content: "<p>...</p>"
      }
    ]
  };
  