// src/pages/Knowledge.js
import React from 'react';
import { Container, Typography, Box, TextField, Grid, Card, CardContent, Link, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import categoriesIcon from '../assets/categories-icon.png'; // Replace or add icons for categories
import heroBg from '../assets/hero-bg.jpg';

const HeroSection = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '30vh',
  background: `
    linear-gradient(to right, rgba(13,71,161,0.6), rgba(30,30,30,0.6)),
    url(${heroBg})
  `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
  color: '#fff',
  textAlign: 'center',
  position: 'relative',
}));

const AnimatedOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '-50%',
  width: '200%',
  height: '100%',
  background: 'radial-gradient(at 50% 50%, rgba(255,255,255,0.05), transparent 40%)',
  animation: 'floatAnimation 10s infinite linear',
  '@keyframes floatAnimation': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-10%)' },
    '100%': { transform: 'translateX(0)' },
  },
  pointerEvents: 'none',
  zIndex: 1,
}));

const HeroContent = styled(Box)(({ theme }) => ({
  maxWidth: '700px',
  zIndex: 2,
}));

const CategoryCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  height: '100%',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const ArticleCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const Knowledge = () => {
  return (
    <>
      <HeroSection>
        <AnimatedOverlay />
        <HeroContent>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            Orthinas Knowledge Base
          </Typography>
          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
            Your portal to tips, guides, and answers for all Orthinas products.
          </Typography>
          <TextField 
            variant="outlined" 
            placeholder="Search articles..."
            fullWidth
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </HeroContent>
      </HeroSection>

      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
          Browse by Category
        </Typography>
        <Typography variant="body1" paragraph>
          Explore our curated categories to find in-depth guides, step-by-step tutorials, and troubleshooting advice. As Orthinas grows, our knowledge base will expand—ensuring you always have the information you need at your fingertips.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={6} md={4}>
            <CategoryCard variant="outlined">
              <CardContent>
                <Box mb={2}>
                  <Box component="img" src={categoriesIcon} alt="Category Icon" sx={{ height: 40, width: 'auto' }} />
                </Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Getting Started
                </Typography>
                <Typography variant="body2" paragraph>
                  Learn the basics of using Orthinas Forms and other tools, perfect if you're new or just getting up to speed.
                </Typography>
                <Link href="#" underline="hover" color="secondary">
                  View Articles
                </Link>
              </CardContent>
            </CategoryCard>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <CategoryCard variant="outlined">
              <CardContent>
                <Box mb={2}>
                  <Box component="img" src={categoriesIcon} alt="Category Icon" sx={{ height: 40, width: 'auto' }} />
                </Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Advanced Features
                </Typography>
                <Typography variant="body2" paragraph>
                  Unlock the full potential of Orthinas solutions with in-depth explorations of advanced functionalities.
                </Typography>
                <Link href="#" underline="hover" color="secondary">
                  View Articles
                </Link>
              </CardContent>
            </CategoryCard>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <CategoryCard variant="outlined">
              <CardContent>
                <Box mb={2}>
                  <Box component="img" src={categoriesIcon} alt="Category Icon" sx={{ height: 40, width: 'auto' }} />
                </Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Troubleshooting
                </Typography>
                <Typography variant="body2" paragraph>
                  Find solutions to common issues and learn how to quickly resolve them, keeping your workflow running smoothly.
                </Typography>
                <Link href="#" underline="hover" color="secondary">
                  View Articles
                </Link>
              </CardContent>
            </CategoryCard>
          </Grid>
        </Grid>

        {/* Featured Articles Section */}
        <Box sx={{ mt: 10 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Featured Articles
          </Typography>
          <Typography variant="body1" paragraph>
            Not sure where to start? Check out these popular guides and resources that our users find most helpful.
          </Typography>

          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} sm={6}>
              <ArticleCard variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                    5 Tips to Maximize Orthinas Forms Efficiency
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Get more done in less time. Learn how to configure custom workflows, set conditional logic, and integrate with third-party tools.
                  </Typography>
                  <Link href="#" underline="hover" color="primary">
                    Read More
                  </Link>
                </CardContent>
              </ArticleCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ArticleCard variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Troubleshooting Common Form Submission Issues
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Encountering errors or slow submission times? This guide walks you through diagnosing and resolving common issues.
                  </Typography>
                  <Link href="#" underline="hover" color="primary">
                    Read More
                  </Link>
                </CardContent>
              </ArticleCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Knowledge;
