// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Footer from './components/Footer';
import theme from './theme';
import Knowledge from './pages/Knowledge';
import ArticleDetail from './pages/ArticleDetail';
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navigation />
        {/* Add some spacing/margin-top on your pages to avoid content hiding behind the nav */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/articles/:category/:slug" element={<ArticleDetail />} />

          {/* Optional NotFound component */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
