import React from 'react';
import { Box, Container, Typography, Grid, Link, IconButton, Divider } from '@mui/material';
import { styled } from '@mui/system';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const FooterContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to right, #0D47A1, #1E1E1E)',
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const Footer = () => (
  <FooterContainer component="footer">
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Orthinas by RCF
          </Typography>
          <Typography variant="body2">
            Empowering digital innovation through celestial-inspired solutions.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <IconButton 
              aria-label="Twitter" 
              component={Link} 
              href="https://twitter.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton 
              aria-label="LinkedIn" 
              component={Link} 
              href="https://linkedin.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton 
              aria-label="GitHub" 
              component={Link} 
              href="https://github.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              color="inherit"
            >
              <GitHubIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" gutterBottom>
            Quick Links
          </Typography>
          <Typography variant="body2">
            <Link href="/" color="inherit" underline="hover">Home</Link><br />
            <Link href="/about" color="inherit" underline="hover">About</Link><br />
            <Link href="/contacts" color="inherit" underline="hover">Contacts</Link><br />
            <Link href="https://forms.orthinas.com" color="inherit" underline="hover">Orthinas Forms</Link><br />
            <Link href="/knowledge" color="inherit" underline="hover">Knowledge Base</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" gutterBottom>
            Learn More
          </Typography>
          <Typography variant="body2">
            Orthinas is a brand of <Link href="https://www.rcf-company-website.com" color="inherit" underline="hover">RCF</Link>, committed to powering the future of work through advanced technology solutions.  
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4, borderColor: 'rgba(255,255,255,0.2)' }} />
      <Typography variant="body2" color="textSecondary" textAlign="center">
        &copy; {new Date().getFullYear()} Orthinas by RCF. All rights reserved.
      </Typography>
    </Container>
  </FooterContainer>
);

export default Footer;
