// src/components/Navigation.js
import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '/Users/willradley/orthinas/src/assets/logo.png'

const Navigation = () => (
  <AppBar position="fixed" elevation={2}>
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', minHeight: 64 }}>
      <Box
        component={Link}
        to="/"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit'
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Orthinas Logo"
          sx={{ height: 40, width: 'auto' }}
        />
      </Box>
      <Box>
        <Button color="inherit" component={Link} to="/">
          Home
        </Button>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
        
        <Button color="inherit" component={Link} to="/knowledge">
          Knowledge
        </Button>
      </Box>
    </Toolbar>
  </AppBar>
);

export default Navigation;
