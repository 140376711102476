// src/pages/Contacts.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Contacts = () => (
  <Container maxWidth="md">
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Orinthia Contacts
      </Typography>
      <Typography variant="body1" paragraph>
        Our first app available is Orinthia Contacts, a contacts management app designed to streamline your business communications.
      </Typography>
    </Box>
  </Container>
);

export default Contacts;
