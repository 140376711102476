// src/pages/Home.js
import React from 'react';
import { Container, Typography, Box, Button, Grid, Paper, Link, Card, CardContent, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import rcfLogo from '../assets/rcfLogo.png';       // Ensure these paths are correct
import heroBg from '../assets/hero-bg.jpg';       // Subtle background image

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '75vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  padding: theme.spacing(12, 2),
  textAlign: 'center',
  background: `
    linear-gradient(to right, rgba(13,71,161,0.6), rgba(30,30,30,0.6)),
    url(${heroBg})
  `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',  
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(10, 2),
  },
}));

const AnimatedOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '-50%',
  width: '200%',
  height: '100%',
  background: 'radial-gradient(at 50% 50%, rgba(255,255,255,0.05), transparent 40%)',
  animation: 'floatAnimation 10s infinite linear',
  '@keyframes floatAnimation': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-10%)' },
    '100%': { transform: 'translateX(0)' },
  },
  pointerEvents: 'none',
  zIndex: 1,
}));

const HeroContent = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  zIndex: 2,
}));

const RcfLogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  opacity: 0.15,
  filter: 'grayscale(100%)',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.4,
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: theme.palette.background.paper,
  minHeight: '180px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const WaveDivider = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100px',
  overflow: 'hidden',
  marginTop: theme.spacing(-10),
  zIndex: 3,
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '-75px',
    width: '100%',
    height: '200px',
    background: theme.palette.background.default,
    borderRadius: '100% 50%',
    transform: 'scale(2)',
  },
}));

const Home = () => (
  <>
    {/* Hero Section */}
    <HeroSection>
      <AnimatedOverlay />
      <HeroContent>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Discover Orthinas
        </Typography>
        <Typography variant="h6" component="p" paragraph sx={{ mb: 4 }}>
          Transforming complex workflows into streamlined, insightful experiences—guided by the stars and powered by RCF.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
          <Button 
            variant="contained" 
            color="primary" 
            href="https://forms.orthinas.com"
            sx={{ textTransform: 'none', fontWeight: 'bold', minWidth: 180 }}
          >
            Explore Forms
          </Button>
          <Button 
            variant="outlined" 
            color="secondary"
            href="/knowledge"
            sx={{ textTransform: 'none', fontWeight: 'bold', minWidth: 180 }}
          >
            Knowledge Base
          </Button>
        </Box>
      </HeroContent>
      <RcfLogoBox 
        component="a" 
        href="https://www.rcf-company-website.com" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <Box
          component="img"
          src={rcfLogo}
          alt="RCF Logo"
          sx={{ height: 40, width: 'auto' }}
        />
      </RcfLogoBox>
    </HeroSection>

    {/* Wave Divider between Hero and Content */}
    <WaveDivider />

    {/* Main Content Section */}
    <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
      <Typography variant="body1" paragraph>
        Orthinas, a brand of <Link href="https://www.rcf-company-website.com" color="secondary" underline="hover">RCF</Link>, empowers businesses with intuitive software solutions. Inspired by the cosmos, we illuminate the path to operational clarity, efficiency, and innovation.
      </Typography>
      <Typography variant="body1" paragraph>
        Our flagship product, <Link href="https://forms.orthinas.com" color="primary" underline="hover">Orthinas Forms</Link>, is just the starting point. Whether refining established processes or embracing new frontiers, Orthinas will guide you toward a future defined by agility and insight.
      </Typography>

      {/* Features Section */}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          Why Choose Orthinas?
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <FeatureCard elevation={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Intuitive Interface
              </Typography>
              <Typography variant="body2">
                Seamlessly integrate Orthinas tools into your workflow with minimal training, thanks to user-friendly design.
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard elevation={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Data-Driven Insights
              </Typography>
              <Typography variant="body2">
                Leverage powerful analytics to make informed decisions, improve processes, and stay ahead in a competitive market.
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard elevation={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Future-Ready
              </Typography>
              <Typography variant="body2">
                Orthinas evolves with technology, ensuring your business remains agile and prepared for tomorrow’s challenges.
              </Typography>
            </FeatureCard>
          </Grid>
        </Grid>
      </Box>

    =
    </Container>
  </>
);

export default Home;
