import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import rcfLogo from '../assets/rcfLogo.png'; // Adjust path as needed

const HeroSection = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '40vh',
  background: 'linear-gradient(to right, #0D47A1, #1E1E1E)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
  color: '#fff',
  textAlign: 'center',
  position: 'relative',
}));

const RcfLogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  opacity: 0.2,
  filter: 'grayscale(100%)',
  '&:hover': {
    opacity: 0.5,
  },
}));

const About = () => {
  return (
    <>
      <HeroSection>
        <Box maxWidth="md">
          <Typography variant="h3" component="h1" gutterBottom>
            About Orthinas
          </Typography>
          <Typography variant="h6" paragraph>
            Forging Pathways to Innovation, Guided by Celestial Inspiration
          </Typography>
        </Box>
        <RcfLogoBox
          component="a"
          href="https://www.rcf-company-website.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={rcfLogo}
            alt="RCF Logo"
            sx={{ height: 40, width: 'auto' }}
          />
        </RcfLogoBox>
      </HeroSection>

      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="body1" paragraph>
          Orthinas is a forward-thinking software brand owned by <strong>RCF</strong>, dedicated to empowering organizations through advanced digital solutions. Inspired by the cosmos—where countless stars guide navigators through the unknown—we envision our products as guiding lights, illuminating the path to growth and efficiency.
        </Typography>
        <Typography variant="body1" paragraph>
          From our flagship product, Orthinas Forms, to the many solutions we have planned for the future, our mission is to streamline workflows, foster smarter decision-making, and push boundaries in an ever-changing digital landscape.
        </Typography>

        <Box sx={{ mt: 6, mb: 6 }}>
          <Typography variant="h5" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1">
            To harness technology as a catalyst for innovation, providing businesses with the clarity, agility, and tools needed to navigate complexity, achieve excellence, and discover new frontiers of possibility.
          </Typography>
        </Box>

        <Typography variant="h5" gutterBottom>
          Our Journey
        </Typography>
        <Typography variant="body1" paragraph>
          Though Orthinas is a young brand, our parent company, RCF, has a long history of championing forward-looking solutions. We carry that legacy into the modern world, leveraging RCF’s established expertise to bring cutting-edge products to market.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{ bgcolor: 'background.paper' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  2020
                </Typography>
                <Typography variant="body2">
                  RCF sets out to build a new software ecosystem aimed at assisting businesses in a rapidly evolving tech landscape.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{ bgcolor: 'background.paper' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  2022
                </Typography>
                <Typography variant="body2">
                  Orthinas brand is conceptualized, drawing inspiration from celestial navigation—an anchor in uncertain times.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{ bgcolor: 'background.paper' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  2024 & Beyond
                </Typography>
                <Typography variant="body2">
                  Orthinas Forms is launched, with more products on the horizon. We continue to expand our suite and refine our approach.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ mt: 8 }}>
          <Typography variant="h5" gutterBottom>
            Shaping Tomorrow
          </Typography>
          <Typography variant="body1">
            As we look to the future, Orthinas remains committed to innovation, guided by a vision that extends far beyond today’s challenges. We believe in empowering businesses not just to adapt, but to thrive—under any set of stars.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default About;
